<template>
  <div>
    <div
      v-if="!(picoConsolidation && picoConsolidation.length > 0)"
      class="output"
    >
      Consolidation is loading...
    </div>
    <div
      v-if="picoConsolidation && picoConsolidation.length > 0"
      class="output"
    >
      <h4>PICO Consolidation</h4>
      <q-table
        :rows="picoConsolidation"
        :columns="columns"
        row-key="picoNumber"
        virtual-scroll
        :virtual-scroll-sticky-size-start="48"
      >
        <template v-slot:body-cell-outcomes="props">
          <q-td :props="props">
            <ul>
              <li v-for="outcome in props.value" :key="outcome">
                {{ outcome }}
              </li>
            </ul>
          </q-td>
        </template>
      </q-table>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'PICOConsolidation',
  props: {
    assetId: {
      type: String,
      required: true,
    },
    assetName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      picoConsolidation: [],
      consolidationHistory: [],
      isAdmin: false,
      columns: [
        {
          name: 'picoNumber',
          label: 'PICO No.',
          field: 'picoNumber',
          align: 'left',
        },
        {
          name: 'population',
          label: 'Population',
          field: 'population',
          align: 'left',
        },
        {
          name: 'intervention',
          label: 'Intervention',
          field: 'intervention',
          align: 'left',
        },
        {
          name: 'comparator',
          label: 'Comparator',
          field: 'comparator',
          align: 'left',
        },
        {
          name: 'outcomes',
          label: 'Outcome(s)',
          field: 'outcomes',
          align: 'left',
        },
        {
          name: 'relevantCountries',
          label: 'Relevant Countr(y)ies',
          field: 'relevantCountries',
          align: 'left',
          format: (val) => val.join(', '),
        },
        {
          name: 'relevantPopulationPercentage',
          label: 'Relevant for % of EU Population',
          field: 'relevantPopulationPercentage',
          align: 'left',
          format: (val) => `${val}%`,
        },
        {
          name: 'populationBasedRelevance',
          label: 'Population-based Relevance',
          field: 'populationBasedRelevance',
          align: 'left',
        },
      ],
    }
  },
  mounted() {
    this.generatePICOConsolidation()
  },
  methods: {
    async generatePICOConsolidation() {
      try {
        const picoResponse = await axios.get(`jca/fetchPicos/${this.assetId}`)
        var picos = picoResponse.data

        const groupedByCombinations = picos.reduce((acc, curr) => {
          const vizId = curr.visualizationId

          if (!acc[vizId]) {
            acc[vizId] = {
              combinations: [],
              intervention: curr.intervention,
              outcomes: curr.outcomes,
              country: curr.country,
              population: curr.population,
              description: curr.description,
              multipleComparatorId: curr.multipleComparatorId,
              assetId: curr.assetId,
              id: curr.id,
              visualizationId: curr.visualizationId,
            }
          }
          acc[vizId].combinations.push(curr.combinations)
          return acc
        }, {})

        // Merge PICOs based on the specified rules
        const mergedPicos = this.mergePicos(
          Object.values(groupedByCombinations),
        )
        console.log('mergedPicos', mergedPicos)
        // Transform the merged data to the desired structure
        // Transform the merged data to the desired structure
        this.picoConsolidation = await Promise.all(
          mergedPicos.map(async (pico, index) => ({
            picoNumber: index + 1,
            population: pico.population,
            intervention: pico.intervention,
            comparator: pico.combinations.join(', '),
            outcomes: pico.outcomes,
            relevantCountries: pico.relevantCountries,
            relevantPopulationPercentage:
              await this.calculatePopulationPercentage(pico.relevantCountries),
            populationBasedRelevance: this.getPopulationBasedRelevance(
              await this.calculatePopulationPercentage(pico.relevantCountries),
            ),
          })),
        )

        this.saveConsolidationVersion(this.picoConsolidation)
      } catch (error) {
        console.error('Error fetching PICOs:', error)
      }
    },

    mergePicos(picos) {
      const merged = {}
      const combinationCounts = {}
      const combinationCountries = {}

      // Count occurrences of each combination and track countries
      picos.forEach((pico) => {
        pico.combinations.forEach((combination) => {
          const individualCombinations = combination
            .split(',')
            .map((c) => c.trim())

          individualCombinations.forEach((individualCombination) => {
            // Create a unique key for counting based on population, intervention, and individual combination
            const countKey = `${pico.population}|${pico.intervention}|${individualCombination}`
            combinationCounts[countKey] = (combinationCounts[countKey] || 0) + 1

            // Create a unique key based on population, intervention, and individual combination for countries
            const countryKey = `${pico.population}|${pico.intervention}|${individualCombination}`
            if (!combinationCountries[countryKey]) {
              combinationCountries[countryKey] = new Set()
            }

            // Add the country to the set for the specific key
            combinationCountries[countryKey].add(pico.country)
          })
        })
      })

      picos.forEach((pico) => {
        let processedCombinations = [
          ...pico.combinations[0].split(',').map((c) => c.trim()),
        ]

        if (pico.multipleComparatorId === 0) {
          // Keep the most frequent combination based on combinationCounts
          const mostFrequent = processedCombinations.reduce((prev, curr) => {
            const prevCount =
              combinationCounts[
                `${pico.population}|${pico.intervention}|${prev}`
              ] || 0
            const currCount =
              combinationCounts[
                `${pico.population}|${pico.intervention}|${curr}`
              ] || 0

            return currCount > prevCount ? curr : prev
          })

          processedCombinations = [mostFrequent]
          // Create a unique key for the merged pico
          const key = `${pico.population}|${pico.intervention}|${mostFrequent}`

          // Set relevantCountries from combinationCountries
          const relevantCountries = Array.from(combinationCountries[key] || [])

          if (!merged[key]) {
            // If the key doesn't exist, create a new entry
            merged[key] = {
              ...pico,
              combinations: processedCombinations,
              outcomes: pico.outcomes ? pico.outcomes.split(', ') : [],
              relevantCountries, // Set from combinationCountries
            }
          } else {
            // If the key already exists, merge outcomes and relevant countries
            merged[key].outcomes = [
              ...new Set([
                ...merged[key].outcomes,
                ...(pico.outcomes ? pico.outcomes.split(', ') : []),
              ]),
            ]
            // Add countries from combinationCountries if not already included
            relevantCountries.forEach((country) => {
              if (!merged[key].relevantCountries.includes(country)) {
                merged[key].relevantCountries.push(country)
              }
            })
          }
        } else if (pico.multipleComparatorId === 1) {
          // For each combination, create a new PICO entry
          processedCombinations.forEach((combination) => {
            const key = `${pico.population}|${pico.intervention}|${combination}`
            const relevantCountries = Array.from(
              combinationCountries[key] || [],
            )

            if (!merged[key]) {
              merged[key] = {
                ...pico,
                combinations: [combination], // Only include the current combination
                outcomes: pico.outcomes ? pico.outcomes.split(', ') : [],
                relevantCountries, // Set from combinationCountries
              }
            } else {
              // Merge outcomes
              merged[key].outcomes = [
                ...new Set([
                  ...merged[key].outcomes,
                  ...(pico.outcomes ? pico.outcomes.split(', ') : []),
                ]),
              ]
              // Add countries from combinationCountries if not already included
              relevantCountries.forEach((country) => {
                if (!merged[key].relevantCountries.includes(country)) {
                  merged[key].relevantCountries.push(country)
                }
              })
            }
          })
        }
      })

      return Object.values(merged)
    },
    async calculatePopulationPercentage(countries) {
      let totalPercentage = 0 // Variable to hold the total percentage

      // Use for...of loop to await each request
      for (const country of countries) {
        const response = await axios.get(`jca/fetchPercentage/${country}`)

        totalPercentage += response.data // Add the response data to the total percentage
      }

      return totalPercentage // Return the total percentage
    },
    saveConsolidationVersion(data) {
      const currentDate = new Date().toLocaleString()
      this.consolidationHistory.push({ date: currentDate, data })
    },
    viewConsolidation(data) {
      this.picoConsolidation = data
    },
    deleteVersion(index) {
      if (this.isAdmin) {
        this.consolidationHistory.splice(index, 1)
      } else {
        console.log(
          'You do not have permission to delete consolidation versions.',
        )
      }
    },
    getPopulationBasedRelevance(percentage) {
      if (percentage > 10) {
        return 'high'
      } else if (percentage >= 3 && percentage <= 10) {
        return 'medium'
      } else {
        return 'low'
      }
    },
  },
}
</script>
