<template>
  <div>
    <div>
      <q-card class="modal-content">
        <q-card-section>
          <div class="text-h6">Add New Population</div>
        </q-card-section>
        <div class="modal-body">
          <div class="population-form">
            <div class="form-group" v-if="isAddingNewPopulation">
              <label>New Population Name:</label>
              <div class="form-control">
                <input
                  type="text"
                  v-model="newPopulationName"
                  placeholder="Enter new population name"
                />
              </div>
            </div>
            <div class="form-group">
              <label>Disease:</label>
              <div class="form-control">
                <input
                  type="text"
                  v-model="population.disease"
                  placeholder="Enter disease"
                />
              </div>
            </div>
            <div class="form-group">
              <label
                >Disease characteristics (e.g. severity, stage of
                progression):</label
              >
              <div class="form-control">
                <input
                  type="text"
                  v-model="population.diseaseCharacteristics"
                  placeholder="Enter disease characteristics"
                />
              </div>
            </div>
            <div class="form-group">
              <label>Socio-demographic restrictions:</label>
              <div class="form-control">
                <select v-model="population.sociodemographicRestrictions">
                  <option value="">None</option>
                  <option value="restrictions">
                    Restrictions, e.g. in relation to age/gender
                  </option>
                </select>
                <input
                  v-if="
                    population.sociodemographicRestrictions === 'restrictions'
                  "
                  type="text"
                  v-model="sociodemographicRestrictionsText"
                  placeholder="Enter socio-demographic restrictions"
                />
              </div>
            </div>
            <div class="form-group">
              <label>Previous treatments/treatment line:</label>
              <div class="form-control">
                <select v-model="population.previousTreatments">
                  <option value="">None</option>
                  <option value="specify">Specify</option>
                </select>
                <input
                  v-if="population.previousTreatments === 'specify'"
                  type="text"
                  v-model="previousTreatmentsText"
                  placeholder="Enter previous treatments/treatment line"
                />
              </div>
            </div>
            <div class="form-group">
              <label>Other criteria:</label>
              <div class="form-control">
                <select v-model="population.otherCriteria">
                  <option value="">None</option>
                  <option value="specify">Specify other criteria</option>
                </select>
                <input
                  v-if="population.otherCriteria === 'specify'"
                  type="text"
                  v-model="otherCriteriaText"
                  placeholder="Enter other criteria"
                />
              </div>
            </div>
            <div class="form-group">
              <label>Final description for the patient population:</label>
              <div class="form-control">
                <div class="description-display">
                  {{ finalDescription }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <q-btn
            @click="showConfirmationDialog"
            color="primary"
            class="add-new-btn neumorphic-btn"
          >
            Confirm
          </q-btn>
          <q-btn
            @click="$emit('close')"
            color="primary"
            class="add-new-btn neumorphic-btn"
            >Cancel</q-btn
          >
        </div>
      </q-card>
    </div>

    <!-- Confirmation Dialog -->
    <div v-if="showDialog" class="dialog-overlay">
      <div class="dialog-content">
        <h4>Confirm Population Description</h4>
        <p>{{ finalDescription }}</p>
        <q-btn
          color="primary"
          class="add-new-btn neumorphic-btn"
          @click="confirmPopulation"
        >
          Use This Description
        </q-btn>
        <q-btn
          color="primary"
          class="add-new-btn neumorphic-btn"
          @click="showDialog = false"
          >Cancel</q-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isAddingNewPopulation: false,
      selectedPopulation: null,
      newPopulationName: '',
      population: {
        disease: '',
        diseaseCharacteristics: '',
        sociodemographicRestrictions: '',
        previousTreatments: '',
        otherCriteria: '',
        description: '',
      },
      sociodemographicRestrictionsText: '',
      previousTreatmentsText: '',
      otherCriteriaText: '',
      showDialog: false,
    }
  },
  computed: {
    finalDescription() {
      const descriptionParts = []
      if (this.population.disease) {
        descriptionParts.push(`Patients with ${this.population.disease}`)
      }
      if (this.population.diseaseCharacteristics) {
        descriptionParts.push(`(${this.population.diseaseCharacteristics})`)
      }
      if (
        this.population.sociodemographicRestrictions === 'restrictions' &&
        this.sociodemographicRestrictionsText
      ) {
        descriptionParts.push(this.sociodemographicRestrictionsText)
      }
      if (
        this.population.previousTreatments === 'specify' &&
        this.previousTreatmentsText
      ) {
        descriptionParts.push(
          `Previous treatments: ${this.previousTreatmentsText}`,
        )
      }
      if (
        this.population.otherCriteria === 'specify' &&
        this.otherCriteriaText
      ) {
        descriptionParts.push(this.otherCriteriaText)
      }
      return descriptionParts.join('; ')
    },
  },
  methods: {
    handleModelValueUpdate(value) {
      if (value.value === null) {
        this.isAddingNewPopulation = true
        this.newPopulationName = ''
        this.population = {
          disease: '',
          diseaseCharacteristics: '',
          sociodemographicRestrictions: '',
          previousTreatments: '',
          otherCriteria: '',
          description: '',
        }
        this.sociodemographicRestrictionsText = ''
        this.previousTreatmentsText = ''
        this.otherCriteriaText = ''
      } else {
        this.isAddingNewPopulation = false
        console.log('Selected population:', value.value)
      }
    },
    showConfirmationDialog() {
      const emptyFields = this.validateFields()
      if (emptyFields.length > 0) {
        const fieldNames = emptyFields.join(', ')
        alert(
          `The following fields are empty: ${fieldNames}. Please fill them before confirming.`,
        )
        return
      }
      this.showDialog = true
    },
    validateFields() {
      const emptyFields = []
      if (!this.population.disease) emptyFields.push('Disease')
      if (!this.population.diseaseCharacteristics)
        emptyFields.push('Disease characteristics')
      if (
        this.sociodemographicRestrictionsText === '' &&
        this.population.sociodemographicRestrictions === 'restrictions'
      ) {
        emptyFields.push('Sociodemographic Restrictions')
      }
      if (
        this.previousTreatmentsText === '' &&
        this.population.previousTreatments === 'specify'
      ) {
        emptyFields.push('Previous Treatments')
      }
      if (
        this.otherCriteriaText === '' &&
        this.population.otherCriteria === 'specify'
      ) {
        emptyFields.push('Other Criteria')
      }
      return emptyFields
    },
    confirmPopulation() {
      const emptyFields = this.validateFields()
      if (emptyFields.length > 0) {
        const fieldNames = emptyFields.join(', ')
        const confirmDelete = confirm(
          `The following fields are empty: ${fieldNames}. If you continue, your input will be deleted. Do you want to proceed?`,
        )
        if (!confirmDelete) {
          return
        }
      }
      this.population.description = this.finalDescription
      if (
        this.population.otherCriteria === 'specify' &&
        this.otherCriteriaText
      ) {
        this.population.otherCriteria = `${this.population.otherCriteria}: ${this.otherCriteriaText}`
      }

      if (
        this.population.previousTreatments === 'specify' &&
        this.previousTreatmentsText
      ) {
        this.population.previousTreatments = `${this.population.previousTreatments}: ${this.previousTreatmentsText}`
      }

      if (
        this.population.sociodemographicRestrictions === 'restrictions' &&
        this.sociodemographicRestrictionsText
      ) {
        this.population.sociodemographicRestrictions = `${this.population.sociodemographicRestrictions}: ${this.sociodemographicRestrictionsText}`
      }
      this.$emit('addPopulation', this.population)
      this.$emit('close')
      this.showDialog = false
    },
  },
}
</script>

<style scoped>
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.dialog-content {
  background-color: #fff;
  padding: 16px;
  border-radius: 4px;
  max-width: 400px;
  text-align: center;
}

.btn-cancel {
  background-color: #dc3545; /* Bootstrap danger color */
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.btn-add {
  background-color: #007bff;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-content {
  background-color: #fff;
  border-radius: 4px;
  max-width: 600px;
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;
  padding: 16px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.close-button {
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-body {
  margin-bottom: 16px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}

.btn-confirm {
  background-color: #28a745;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-group {
  margin-bottom: 16px;
}

.form-control {
  display: flex;
  align-items: center;
}

.form-control input,
.form-control textarea,
.form-control select {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-control textarea {
  resize: vertical;
}

@media (max-width: 480px) {
  .modal-content {
    width: 95%;
  }
}
</style>
