<template>
  <div class="specify-outcome-form">
    <div class="form-group">
      <label>List of defined outcomes:</label>
      <div class="explanatory-text">
        <p>
          In this final step, you will list the relevant outcome(s) that will be
          used to assess the clinical efficacy, safety, and overall impact of
          the intervention for the specified population. Outcomes of interest
          can include clinical endpoints (e.g., survival, disease progression),
          patient-reported outcomes (e.g., quality of life), and safety measures
          (e.g., adverse events). For each outcome, you must specify:
        </p>
        <ul>
          <li>
            <b>Outcome Measure:</b> The specific endpoint to be assessed (e.g.,
            median progression-free survival, EQ-5D score, frequency of acute
            infections)
          </li>
          <li>
            <b>Time of Assessment: </b> The medical treatment or procedure being
            evaluated
          </li>
          <li>
            <b>Statistical Measurement for Effect Size:</b> Alternative
            treatments or standard care options
          </li>
        </ul>
        <p>
          <b>Important: </b>It’s crucial to define these elements for each
          outcome to ensure clarity and consistency in the assessment. HTA
          authorities and payers often require specific outcome measures and
          statistical methods to demonstrate the clinical and economic value of
          the intervention. Be sure to include all necessary outcomes and detail
          how they will be measured and analyzed for both the intervention and
          comparator(s). Once the outcomes are fully defined for the current
          population, intervention, and comparator(s), you can proceed to define
          additional populations, if needed. Each new PICO will require its own
          set of outcomes.
        </p>
      </div>
      <q-table
        :rows="localOutcomes"
        :columns="columns"
        row-key="id"
        class="q-mt-md"
      >
        <template v-slot:body-cell-select="props">
          <q-td>
            <q-checkbox v-model="props.row.selected" />
          </q-td>
        </template>

        <template v-slot:body-cell-name="props">
          <q-td>{{ props.row.name }}</q-td>
        </template>

        <template v-slot:body-cell-category="props">
          <q-td>{{ props.row.category }}</q-td>
        </template>

        <template v-slot:body-cell-outcomeMeasure="props">
          <q-td>{{ props.row.outcomeMeasure }}</q-td>
        </template>

        <template v-slot:body-cell-timeAssessment="props">
          <q-td>{{ props.row.timeAssessment }}</q-td>
        </template>

        <template v-slot:body-cell-statisticalMeasurement="props">
          <q-td>{{ props.row.statisticalMeasurement }}</q-td>
        </template>
      </q-table>
    </div>

    <div class="form-actions">
      <q-btn
        @click="showOutcomeModal = true"
        color="primary"
        class="add-new-btn neumorphic-btn"
      >
        Add New Outcome
      </q-btn>
      <q-btn
        @click="emitBack"
        color="primary"
        class="add-new-btn neumorphic-btn"
      >
        Back
      </q-btn>
      <q-btn
        @click="finishPico"
        color="primary"
        class="add-new-btn neumorphic-btn"
      >
        Save PICO
      </q-btn>
    </div>

    <OutcomeModal
      v-if="showOutcomeModal"
      :selectedOutcomeOptions="selectedOutcomeOptions"
      @close="showOutcomeModal = false"
      @addOutcome="addOutcome"
    />
    <ConfirmationModal
      :isVisible="showModal"
      title="PICO Saved"
      message="Would you like to specify another PICO?"
      :confirmText="'Yes, specify another PICO for this country'"
      :alternativeText="'Yes, specify another PICO for another country'"
      :cancelText="'No, I am done specifying PICOs'"
      @confirm="handleSpecifyAnotherPICO"
      @alternative="handleSpecifyAnotherPICOForAnotherCountry"
      @cancel="handleNoSpecifyAnotherPICO"
    />
    <div
      v-if="saveFeedback.message"
      :class="['feedback-message', saveFeedback.type]"
    >
      {{ saveFeedback.message }}
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import ConfirmationModal from './ConfirmationModal.vue'
import OutcomeModal from './OutcomeModal.vue'

export default {
  name: 'OutcomeForm',
  components: {
    OutcomeModal,
    ConfirmationModal,
  },
  props: {
    assetId: { type: String, required: true },
    picoId: { type: Number, required: true },
  },
  data() {
    return {
      showOutcomeModal: false,
      selectedOutcomeOptions: [],
      localOutcomes: [],
      saveFeedback: {
        message: '',
        type: '', // 'success' or 'error'
      },
      showModal: false,
      columns: [
        {
          name: 'select',
          label: 'Select',
          align: 'left',
          field: 'select',
          sortable: false,
          required: true,
        },
        {
          name: 'name',
          label: 'Name',
          align: 'left',
          field: 'name',
          sortable: true,
        },
        {
          name: 'category',
          label: 'Category',
          align: 'left',
          field: 'category',
          sortable: true,
        },
        {
          name: 'outcomeMeasure',
          label: 'Outcome Measure',
          align: 'left',
          field: 'outcomeMeasure',
          sortable: true,
        },
        {
          name: 'timeAssessment',
          label: 'Time of Assessment',
          align: 'left',
          field: 'timeAssessment',
          sortable: true,
        },
        {
          name: 'statisticalMeasurement',
          label: 'Statistical Measurement',
          align: 'left',
          field: 'statisticalMeasurement',
          sortable: true,
        },
      ],
    }
  },
  computed: {
    isPicoComplete() {
      return (
        this.currentPopulationId !== null &&
        this.currentInterventionId !== null &&
        this.currentComparisonGroupId !== null &&
        this.currentOutcomeIds !== null
      )
    },
  },

  async mounted() {
    const assetOutcomes = await axios.get(`jca/getOutcomeDatas/${this.assetId}`)
    var data = assetOutcomes.data

    this.localOutcomes = data.map((item) => ({
      id: item.id || -1,
      assetId: this.assetId,
      picoId: item.picoId || null,
      name: item.name || '',
      category: this.mapCategoryToDropdownValue(item.category) || '',
      outcomeMeasure: item.outcomeMeasure || '',
      timeAssessment: item.timeAssessment || '',
      statisticalMeasurement: item.statisticalMeasurement || '',
      selected: false, // Default to false initially
    }))

    try {
      const picoOutcomes = await axios.get(
        `jca/getOutcomeDatasForPico/${this.picoId}`,
      )
      const data = picoOutcomes.data
      // Check if data is empty
      if (data.length === 0) {
        return
      }

      // Update selected and picoId for existing localOutcomes
      this.localOutcomes.forEach((outcome) => {
        const matchingData = data.find((item) => item.id === outcome.id)
        if (matchingData) {
          outcome.selected = true // Set selected to true if a match is found
          outcome.picoId = this.picoId // Set picoId
        }
      })
    } catch (error) {
      console.error('Error fetching outcomes:', error)
      this.saveFeedback = {
        message: 'Failed to fetch outcomes. Please try again later.',
        type: 'error',
      }
    }
  },

  methods: {
    emitBack() {
      this.$emit('navigate', 'comparison')
    },

    async finishPico() {
      // Check if at least one outcome is selected
      const isAnyOutcomeSelected = this.localOutcomes.some(
        (outcome) => outcome.selected,
      )

      if (!isAnyOutcomeSelected) {
        alert('Please select at least one outcome before proceeding.')
        return
      }

      // Update picoId based on the selection state of outcomes
      this.localOutcomes.forEach((outcome) => {
        if (!outcome.selected && outcome.picoId === this.picoId) {
          outcome.picoId = null // Reset the picoId if the outcome is deselected
        } else if (outcome.selected && outcome.picoId !== this.picoId) {
          outcome.picoId = this.picoId // Set the picoId if the outcome is selected
        }
      })

      await this.emitOutcomesData()
      this.showModal = true
    },

    async emitOutcomesData() {
      try {
        // Send the outcomes to the server and await the response
        const response = await axios.post(
          `jca/saveOutcomes/${this.picoId}`,
          this.localOutcomes,
        )
        const insertedIds = response.data // Assume this is an array of IDs

        // Update localOutcomes with the returned IDs
        this.localOutcomes.forEach((outcome, index) => {
          if (insertedIds[index]) {
            outcome.id = insertedIds[index] // Update the ID of each outcome
          }
        })
      } catch (error) {
        console.error('Error saving outcomes:', error)
      }
    },

    addOutcome(newOutcome) {
      if (newOutcome.statisticalMeasurement === 'other') {
        newOutcome.statisticalMeasurement =
          newOutcome.statisticalMeasurementOther
      }

      newOutcome.assetId = this.assetId
      newOutcome.selected = false

      // Ensure localOutcomes is defined and push the new outcome
      if (Array.isArray(this.localOutcomes)) {
        this.localOutcomes.push(newOutcome)
      }
      this.showOutcomeModal = false
    },

    mapCategoryToDropdownValue(category) {
      const categoryMapping = {
        mortality: 'Mortality',
        morbidity: 'Morbidity',
        hrqol: 'HrQoL',
        safety: 'Safety',
        other: 'Other',
      }

      return categoryMapping[category.toLowerCase()] || ''
    },

    handleSpecifyAnotherPICO() {
      this.showModal = false
      this.$emit('new-pico') // Emit an event to notify the parent
    },

    handleSpecifyAnotherPICOForAnotherCountry() {
      this.showModal = false
      this.$emit('new-pico-for-another-country') // Emit an event to notify the parent
    },

    handleNoSpecifyAnotherPICO() {
      this.showModal = false
      this.$emit('navigate', 'basicData') // Emit an event to notify the parent
    },
  },
}
</script>

<style scoped>
.specify-outcome-form {
  /* Add your styles */
}

.form-group {
  margin-bottom: 20px;
}

.btn-add {
  margin-right: 10px;
}
</style>
