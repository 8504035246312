<template>
  <div class="country-selector-container">
    <div class="country-selector">
      <label for="country-dropdown">Select a country:</label>
      <select
        id="country-dropdown"
        v-model="localSelectedCountry"
        class="neumorphic-select"
      >
        <option value="" disabled>Select a country</option>
        <option v-for="country in euCountries" :key="country" :value="country">
          {{ country }}
        </option>
      </select>
    </div>
    <q-dialog v-model="showCountrySelector">
      <q-card>
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">Select Country</div>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="cancelPicoSelection"
          />
        </q-card-section>

        <q-card-section>
          <q-select
            v-model="selectedCountry"
            :options="uniqueCountries"
            label="Select a country"
            @update:model-value="handleCountryChange"
          />
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-dialog v-model="showPICOSelector">
      <q-card>
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">Select PICO</div>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="cancelPicoSelection"
          />
        </q-card-section>

        <q-card-section>
          <q-table
            style="max-width: 1800px"
            :rows="picosForSelectedCountry"
            :columns="columns"
            row-key="picoNumber"
            virtual-scroll
            :virtual-scroll-sticky-size-start="48"
          >
            <template v-slot:body-cell="props">
              <q-td :props="props" style="white-space: pre-line">
                <div v-html="props.value"></div>
              </q-td>
            </template>

            <template v-slot:body-cell-actions="props">
              <q-td>
                <q-btn
                  color="primary"
                  class="add-new-btn neumorphic-btn"
                  flat
                  dense
                  icon="edit"
                  @click="selectPICO(props.row)"
                >
                  Select
                </q-btn>
              </q-td>
            </template>
          </q-table>
        </q-card-section>
      </q-card>
    </q-dialog>

    <div class="form-actions" v-if="!clicked">
      <q-btn
        color="primary"
        class="add-new-btn neumorphic-btn"
        label="Create New PICO"
        @click="emitCreateNewPICO"
        :disabled="!localSelectedCountry"
      />
      <q-btn
        color="primary"
        class="add-new-btn neumorphic-btn"
        label="Edit Existing PICO"
        @click="editExistingPico"
        :disabled="!localSelectedCountry"
      />
      <q-btn
        color="primary"
        class="add-new-btn neumorphic-btn"
        label="Select PICO from Another Country"
        @click="createNewPicoFromExisting"
        :disabled="!localSelectedCountry"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'CountrySelectorComponent',

  props: {
    euCountries: {
      type: Array,
      required: true,
    },

    assetId: {
      type: Number,
      required: true,
    },
    showButtons: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      picos: [],
      editPico: false,
      picoId: null,
      clicked: false,
      localSelectedCountry: this.selectedCountry,
      showCountrySelector: false,
      showPICOSelector: false,
      showModal: false,
      columns: [
        {
          name: 'picoNumber',
          label: 'PICO No.',
          field: 'picoNumber',
          align: 'left',
        },
        { name: 'country', label: 'Country', field: 'country', align: 'left' },
        {
          name: 'population',
          label: 'Population',
          field: 'population',
          align: 'left',
        },
        {
          name: 'intervention',
          label: 'Intervention',
          field: 'intervention',
          align: 'left',
        },
        {
          name: 'combinations',
          label: 'Comparator',
          field: 'combinations',
          align: 'left',
        },

        {
          name: 'outcomes',
          label: 'Outcome(s)',
          field: 'outcomes',
          align: 'left',
        },
        {
          name: 'actions',
          label: 'Actions',
          field: 'actions',
          align: 'center',
        },
      ],
    }
  },
  watch: {
    selectedCountry(newValue) {
      this.localSelectedCountry = newValue
    },
    showButtons(newValue) {
      this.clicked = newValue
    },
    localSelectedCountry() {
      if (this.picoId && this.localSelectedCountry) {
        this.picoId = -1
        this.$emit('show-confirmation-modal')
      }
    },
  },
  computed: {
    uniqueCountries() {
      return [...new Set(this.picos.map((pico) => pico.country))]
    },
    picosForSelectedCountry() {
      if (!this.editPico) {
        return this.selectedCountry
          ? this.picos
              .filter((pico) => pico.country === this.selectedCountry)
              .map((pico, index) => {
                return {
                  ...pico,
                  picoNumber: index + 1, // Assign picoNumber based on index
                }
              })
          : []
      }

      return this.localSelectedCountry
        ? this.picos
            .filter((pico) => pico.country === this.localSelectedCountry)
            .map((pico, index) => {
              return {
                ...pico,
                picoNumber: index + 1, // Assign picoNumber based on index
              }
            })
        : []
    },
  },
  mounted() {
    this.clicked = this.showButtons
  },
  methods: {
    handleCountryChange(value) {
      if (this.editPico) {
        this.localSelectedCountry = value
      } else {
        this.selectedCountry = value
      }

      this.showPICOSelector = true
      this.showCountrySelector = false
    },

    handleSpecifyAnotherPICOForAntoherCountry() {
      this.picoId = null
      this.localSelectedCountry = null
      this.showModal = false
      this.clicked = false
    },

    async editExistingPico() {
      await this.loadPicos()
      this.editPico = true
      this.showPICOSelector = true
    },
    onDialogClose() {
      this.showCountrySelector = false
    },
    async createNewPicoFromExisting() {
      await this.loadPicos()
      this.editPico = false
      this.showCountrySelector = true
    },
    cancelPicoSelection() {
      this.showPICOSelector = false
      this.clicked = false
    },
    async selectPICO(pico) {
      this.showPICOSelector = false
      this.showCountrySelector = false
      if (this.editPico) {
        this.picoId = pico.id
      } else {
        this.picoId = pico.id
        await this.copyPICO(pico)
      }
      this.clicked = true
      this.$emit('select-pico-id', this.picoId)

      this.$emit('clicked', this.clicked)
    },
    handleTabNavigation(tabName) {
      this.clicked = false
      if (tabName === 'pico-consolidation') {
        this.$emit('navigate-to-basic-data')
      }
      this.activeTab = tabName // Switch to the specified tab
    },
    async emitCreateNewPICO() {
      this.clicked = true

      const maxVisualizationIdRequestResult = await axios.get(
        'jca/getMaxVisualizationId',
      )
      this.maxVisualizationId = maxVisualizationIdRequestResult.data + 1
      try {
        const response = await axios.post('jca/savePico', {
          assetId: this.assetId,
          country: this.localSelectedCountry,
          visualizationId: this.maxVisualizationId,
        })
        this.picoId = response.data
      } catch (error) {
        console.error(
          'Error creating new PICO:',
          error.response ? error.response.data : error.message,
        )
      }
      this.$emit('select-pico-id', this.picoId)
      this.$emit('clicked', this.clicked)
    },
    async copyPICO() {
      const maxVisualizationIdRequestResult = await axios.get(
        'jca/getMaxVisualizationId',
      )
      this.maxVisualizationId = maxVisualizationIdRequestResult.data + 1
      try {
        const response = await axios.post('jca/copyPico', {
          assetId: this.assetId,
          country: this.localSelectedCountry,
          visualizationId: this.maxVisualizationId,
          oldPicoId: this.picoId,
        })
        this.picoId = response.data
      } catch (error) {
        console.error(
          'Error creating new PICO:',
          error.response ? error.response.data : error.message,
        )
      }
    },
    emitEditExistingPICO() {
      this.$emit('edit-existing-pico', this.localSelectedCountry)
    },
    emitSelectAnotherCountry() {
      this.$emit('select-another-country')
    },
    async loadPicos() {
      const picoResponse = await axios.get('jca/fetchPicos/' + this.assetId)
      this.picos = picoResponse.data
    },
  },
}
</script>
<style scoped>
@media (min-width: 600px) {
  .q-dialog__inner--minimized > div {
    max-width: 800px;
  }
}
</style>
