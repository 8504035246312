<template>
  <div class="comparison-group-form">
    <div class="form-groups-container">
      <div class="form-group-container">
        <div class="form-group">
          <label>Add new Comparator:</label>
          <p>
            In this step, you will define the comparator(s) for the specified
            population and intervention. The comparators represent current
            standard care options, or placebo against which the intervention
            will be evaluated. You can specify these as monotherapy, combination
            therapy, or in conjunction with permitted background treatments
            (e.g., radiation therapy, best supportive care, etc.).<br />
            <b>Important:</b>
            Multiple comparators can be listed. In some cases, a comparative
            analysis against all specified comparators may be mandatory,
            depending on the local HTA requirements. For example, in certain HTA
            or reimbursement decisions, evidence must be provided for each
            comparator to fully assess the intervention’s relative benefit. In
            other cases, comparing the intervention to just one or some of the
            listed comparators may be sufficient. Ensure that all relevant
            comparators for the current population and intervention are fully
            defined before adding additional PICOs with alternative populations
            or interventions.
          </p>
          <p></p>
          <label>Comparator Treatments:</label>
          <p class="info-text">
            The comparators entered are defined as equivalent alternatives for
            this specific PICO:
          </p>
          <q-btn
            @click="openComparatorTable(index)"
            color="primary"
            class="add-new-btn neumorphic-btn"
            style="margin-left: 10px"
          >
            Select Comparator from another PICO
          </q-btn>
          <div
            v-for="(comparator, index) in comparators"
            :key="index"
            class="comparator-group"
          >
            <div
              class="comparator-header"
              style="display: flex; align-items: center"
            >
              <label style="margin-right: 10px"
                >Comparator {{ index + 1 }}:</label
              >

              <q-btn
                v-if="comparators.length > 1"
                flat
                round
                color="red"
                icon="close"
                size="sm"
                @click="removeComparator(index)"
                style="margin-left: auto"
              />
            </div>
            <div class="form-control">
              <input
                type="text"
                v-model="comparator.combinations[0]"
                placeholder="Enter treatment"
                class="input-field"
                required
              />
              <q-btn
                @click="addCombination(index)"
                color="primary"
                class="add-new-btn neumorphic-btn"
                title="Add new combination partner"
              >
                +
              </q-btn>
            </div>
            <div
              v-for="(
                combination, combinationIndex
              ) in comparator.combinations.slice(1)"
              :key="combinationIndex"
              class="combination-treatment"
            >
              <input
                type="text"
                v-model="comparator.combinations[combinationIndex + 1]"
                placeholder="Enter combination treatment"
                class="input-field"
              />
              <q-btn
                flat
                round
                color="red"
                icon="close"
                size="sm"
                @click="removeCombination(index, combinationIndex + 1)"
              />
            </div>
          </div>
          <q-btn
            @click="addComparator"
            color="primary"
            class="add-new-btn neumorphic-btn"
            >Add Comparator</q-btn
          >
        </div>

        <div class="form-group">
          <label>Do you need multiple comparators for this population?</label>
          <div class="radio-group">
            <div class="radio-item">
              <input
                type="radio"
                id="single-comparator"
                v-model="multipleComparators"
                :value="false"
              />
              <label for="single-comparator" class="normal-label">
                Only one of the comparators listed above is required for the JCA
              </label>
            </div>
            <div class="radio-item">
              <input
                type="radio"
                id="multiple-comparators"
                v-model="multipleComparators"
                :value="true"
              />
              <label for="multiple-comparators" class="normal-label">
                All comparator treatments listed above are required for the JCA
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-actions">
      <q-btn
        label="Back"
        @click="emitBack"
        color="primary"
        class="add-new-btn neumorphic-btn"
      />
      <q-btn
        label="Next"
        @click="emitNext"
        color="primary"
        class="add-new-btn neumorphic-btn"
      />
    </div>

    <!-- Modal dialog for the ComparatorTable -->
    <q-dialog v-model="showTable" persistent>
      <q-card>
        <q-card-section>
          <div class="text-h6">Select a Comparator</div>
        </q-card-section>
        <q-card-section>
          <ComparatorTable
            :comparisionGroupOptions="comparisionGroupOptions"
            :comparisonColumns="comparisonColumns"
            @select-comparator="handleComparatorSelection"
            @close="showTable = false"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>
<script>
import axios from 'axios'
import ComparatorTable from './SelectComparator.vue' // Import the child component

export default {
  name: 'ComparisonGroupForm',
  components: {
    ComparatorTable,
  },
  props: {
    assetId: {
      type: String,
      required: true,
    },
    picoId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showTable: false,
      selectedComparator: null,
      comparisionGroupOptions: [],
      comparators: [{ combinations: [''] }],
      multipleComparators: null,
      selectedComparatorIndex: 0, // Add this line to track the selected comparator index
    }
  },
  async mounted() {
    await this.fetchComparisionDatas()
    // Fetch comparison group IDs
    const response = await axios.get(
      `jca/getComparisionGroupForPicoId/${this.picoId}`,
    )
    this.comparisonGroup = response.data[0]
    if (this.comparisonGroup) {
      const comparisonGroup = this.comparisonGroup
      // Initialize the grouped data structure
      const groupedData = {
        id: comparisonGroup.id,
        combinations: {},
        multipleComparators: comparisonGroup.multipleComparators,
      }

      // Process the combinations from the single comparison group
      comparisonGroup.combination.forEach((combo) => {
        const comparatorKey = combo.comparator
        if (!groupedData.combinations[comparatorKey]) {
          groupedData.combinations[comparatorKey] = []
        }
        groupedData.combinations[comparatorKey].push(...combo.combinations)
      })
      // Set the comparators based on groupedData
      this.comparators = Object.keys(groupedData.combinations).map((key) => ({
        combinations: groupedData.combinations[key],
      }))

      this.multipleComparators = this.comparisonGroup.multipleComparators
    }
  },

  computed: {
    selectLabel() {
      return this.comparisionGroupOptions.length === 0
        ? 'No Comparators Available'
        : 'Select Comparator from anoterh PICO'
    },
    comparisonColumns() {
      return [
        {
          name: 'selected',
          label: 'Select',
          align: 'left',
          sortable: false,
        },
        {
          name: 'label',
          label: 'Comparator Group',
          align: 'left',
          field: 'label',
        },
      ]
    },
  },
  methods: {
    openComparatorTable(index) {
      this.resetComparators() // Reset all comparators
      this.selectedComparatorIndex = index // Store the index of the comparator being edited
      this.showTable = true // Show the comparator selection dialog
    },
    resetComparators() {
      // Reset the comparators array to its default state
      this.comparators = [{ combinations: [''] }]
      this.multipleComparators = null // Reset the multiple comparators selection
    },
    emitBack() {
      this.$emit('navigate', 'intervention')
    },
    async emitNext() {
      // Check if the user has selected whether multiple comparators are needed
      if (this.multipleComparators === null) {
        alert('Please select whether you need multiple comparators.')
        return
      }

      // Validate that all comparator fields are filled
      for (const comparator of this.comparators) {
        // Check if the first combination field is empty
        if (!comparator.combinations[0]) {
          alert('Please fill in all required fields for each comparator.')
          return
        }
        // Check if any additional combination fields are empty
        for (const combination of comparator.combinations) {
          if (!combination) {
            alert('Please fill in all required fields for each comparator.')
            return
          }
        }
      }

      const comparisonGroupData = {
        id: this.selectedComparator?.id || -1,
        picoId: this.picoId,
        assetId: this.assetId,
        combination: this.comparators,
        multipleComparators: this.multipleComparators,
      }

      try {
        await axios.post('jca/saveComparisonGroup', comparisonGroupData)
        this.$emit('navigate', 'outcome')
      } catch (error) {
        console.error('Error saving comparison group:', error)
      }
    },

    async fetchComparisionDatas() {
      try {
        const response = await axios.get(
          `jca/getComparisionDatas/${this.assetId}`,
        )
        this.backendData = response.data

        const groupedData = this.backendData.reduce((acc, item) => {
          const key = item.id
          if (!acc[key]) {
            acc[key] = {
              id: key,
              combinations: {},
              multipleComparators: item.multipleComparators,
            }
          }

          item.combination.forEach((combo) => {
            const comparatorKey = combo.comparator
            if (!acc[key].combinations[comparatorKey]) {
              acc[key].combinations[comparatorKey] = []
            }
            acc[key].combinations[comparatorKey].push(...combo.combinations)
          })
          return acc
        }, {})

        let slicedGroupedData = {}
        const keys = Object.keys(groupedData)

        // Remove duplicates from groupedData
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i]
          const comparatorKeys = Object.keys(groupedData[key].combinations)
          for (let j = 0; j < comparatorKeys.length; j++) {
            const uniqueCombinations = Array.from(
              groupedData[key].combinations[comparatorKeys[j]],
            )
            uniqueCombinations.sort((a, b) => b.localeCompare(a))

            for (let k = i + 1; k < keys.length; k++) {
              const secondKey = keys[k]
              const secondComparatorKeys = Object.keys(
                groupedData[secondKey].combinations,
              )
              for (let l = 0; l < secondComparatorKeys.length; l++) {
                const toCompare = Array.from(
                  groupedData[secondKey].combinations[secondComparatorKeys[l]],
                )
                toCompare.sort((a, b) => b.localeCompare(a))

                const isDuplicate =
                  uniqueCombinations.toString() === toCompare.toString()
                if (!isDuplicate) {
                  // Track duplicates in a way that you can filter them out later
                  if (!slicedGroupedData[key]) {
                    slicedGroupedData[key] = { combinations: {} }
                  }
                  if (!slicedGroupedData[key].combinations[comparatorKeys[j]]) {
                    slicedGroupedData[key].combinations[comparatorKeys[j]] = []
                  }
                  slicedGroupedData[key].combinations[comparatorKeys[j]] =
                    uniqueCombinations
                }
              }
            }
          }
        }

        // Create a unique list for comparisionGroupOptions
        this.comparisionGroupOptions = Object.values(slicedGroupedData)
          .flatMap((group) => {
            return Object.keys(group.combinations).map((comparatorKey) => {
              const combos = group.combinations[comparatorKey].join(' + ') // Join combinations into a string
              return {
                value: group,
                label: combos, // Each combination will get its own row
              }
            })
          })
          .filter(
            (option, index, self) =>
              index === self.findIndex((t) => t.label === option.label), // Filter unique labels
          )
      } catch (error) {
        console.error('Error fetching comparison data:', error)
      }
    },
    async handleComparatorSelection(selectedComparatorArray) {
      // Extract the labels from the selectedComparatorArray
      const labelsArray = selectedComparatorArray.map(
        (comparator) => comparator.label,
      )

      for (let i = 0; i < labelsArray.length; i++) {
        const combinationsArray = labelsArray[i].split(' + ')
        for (let j = 0; j < combinationsArray.length; j++) {
          if (!this.comparators[i]) {
            this.addComparator()
          }
          this.comparators[i].combinations[j] = combinationsArray[j]
        }
      }

      // Close the dialog
      this.showTable = false
    },

    addComparator() {
      this.comparators.push({ combinations: [''] })
    },
    removeComparator(index) {
      this.comparators.splice(index, 1)
    },
    addCombination(index) {
      this.comparators[index].combinations.push('')
    },
    removeCombination(comparatorIndex, combinationIndex) {
      this.comparators[comparatorIndex].combinations.splice(combinationIndex, 1)
    },
  },
  watch: {
    comparators: {
      handler: 'emit',
      deep: true,
    },
    selectedComparator(newValue) {
      if (newValue) {
        this.$emit('select-comparator', newValue)
      }
    },
  },
}
</script>

<style scoped>
.comparison-group-form {
  /* Add your styles */
}

.form-groups-container {
  display: flex;
  flex-wrap: nowrap; /* Prevent wrapping to keep items in one row */
}

.form-group {
  flex: 1; /* Allows each group to take equal space */
  margin-right: 20px; /* Space between groups */
  min-width: 300px; /* Ensures a minimum width for responsiveness */
}

.form-group:last-child {
  margin-right: 0; /* Remove margin from the last group */
}

.form-group-container {
  display: flex;
  flex-direction: column; /* Stacks the Comparator Treatments and Multiple Comparators vertically */
  flex: 1; /* Allows this group to take the remaining space */
}

.comparator-group {
  margin-bottom: 15px;
}

.radio-group {
  display: flex;
  flex-direction: column; /* Stack items vertically */
}

.radio-item {
  margin-bottom: 10px; /* Space between each radio item */
}

.normal-label {
  font-weight: normal; /* Remove bold styling */
}

.input-field:invalid {
  border: 2px solid #ff7500; /* Red border for invalid input */
}
</style>
