<template>
  <div class="pico-form neumorphic-container">
    <!-- Display the selected country label here -->
    <div v-if="selectedCountry" class="selected-country-label">
      <b>Selected Country:</b> {{ selectedCountry }}
    </div>
    <!-- Display the selected population label here -->
    <div v-if="population" class="selected-country-label">
      <b>Selected Population:</b> {{ population.description }}
    </div>
    <div v-if="intervention" class="selected-country-label">
      <b>Selected Intervention:</b>
      {{ intervention }}
    </div>

    <q-tabs v-model="activeTab" dense>
      <q-tab name="population" label="Select Population" />
      <q-tab name="intervention" label="Select Intervention" />
      <q-tab name="comparison" label="Select Comparator(s)" />
      <q-tab name="outcome" label="Select Outcome(s)" />
    </q-tabs>

    <q-tab-panels v-model="activeTab">
      <q-tab-panel name="population">
        <PopulationForm
          :picoId="picoId"
          :assetId="assetId"
          @navigate="handleTabNavigation"
        />
      </q-tab-panel>

      <q-tab-panel name="intervention">
        <InterventionForm
          :picoId="picoId"
          :assetId="assetId"
          @navigate="handleTabNavigation"
        />
      </q-tab-panel>

      <q-tab-panel name="comparison">
        <ComparisonGroupForm
          :picoId="picoId"
          :assetId="assetId"
          @navigate="handleTabNavigation"
        />
      </q-tab-panel>

      <q-tab-panel name="outcome">
        <OutcomeForm
          :picoId="picoId"
          :assetId="assetId"
          @navigate="handleTabNavigation"
          @new-pico-for-another-country="handleNewPicoForCountry"
          @new-pico="handleNewPico"
        />
      </q-tab-panel>
    </q-tab-panels>

    <ConfirmationModal
      :isVisible="showModal"
      title="PICO Saved"
      message="Would you like to specify another PICO for this country?"
      @confirm="handleSpecifyAnotherPICO"
      @cancel="handleNoSpecifyAnotherPICO"
    />
    <div
      v-if="saveFeedback.message"
      :class="['feedback-message', saveFeedback.type]"
    >
      {{ saveFeedback.message }}
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import ComparisonGroupForm from './ComparisonGroupForm.vue'
import ConfirmationModal from './ConfirmationModal.vue'
import InterventionForm from './InterventionForm.vue'
import OutcomeForm from './OutcomeForm.vue'
import PopulationForm from './PopulationForm.vue'

export default {
  name: 'PICOForm',
  components: {
    PopulationForm,
    InterventionForm,
    ComparisonGroupForm,
    OutcomeForm,
    ConfirmationModal,
  },
  props: {
    picoId: {
      type: Number,
      required: true,
    },
    assetId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      id: -1,
      localJcaId: this.jcaId,
      saveFeedback: {
        message: '',
        type: '', // 'success' or 'error'
      },
      activeTab: 'population', // Default active tab
      sectionSummaries: {},
      showModal: false,
      population: null,
    }
  },
  computed: {
    visualizationId() {
      return this.selectedPico ? this.selectedPico.visualizationId : null
    },
  },
  methods: {
    handleNewPicoForCountry() {
      this.$emit('new-pico-for-another-country')
    },
    handleNewPico() {
      this.$emit('start-new-pico')
    },
    async handleTabNavigation(tabName) {
      try {
        const populationResponse = await axios.get(
          `jca/getPopulationDatasForPicoId/${this.picoId}`,
        )

        this.population = populationResponse.data[0]
        const interventionStringFromBackendForPicoIdId = await axios.get(
          `jca/getInterventionStringForPicoId/${this.picoId}`,
        )

        this.intervention = interventionStringFromBackendForPicoIdId.data
      } catch (error) {
        console.error('Error fetching data for tab:', error)
      }

      if (tabName === 'basicData') {
        this.$emit('navigate-to-basic-data')
      }
      this.activeTab = tabName // Switch to the specified tab
    },
  },
  watch: {
    jcaId: {
      immediate: true,
      handler(newVal) {
        this.localJcaId = newVal
      },
    },
    activeTab(newTab) {
      console.log('Switched to tab:', newTab)
    },
  },
}
</script>

<style scoped>
.header-container {
  display: flex;
  align-items: center; /* Vertically center align */
  margin-bottom: 20px; /* Space between header and tabs */
}

.define-pico-header {
  margin-right: 10px; /* Space between header and selector */
}

.pico-selector {
  margin-left: auto; /* Push selector to the right */
}

.section-summary {
  font-size: 0.9em;
  color: #666;
  margin-top: 5px;
  padding-left: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%; /* adjust as needed */
}

.feedback-message {
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
  font-weight: bold;
}

.feedback-message.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.feedback-message.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}
.missing-fields {
  margin-top: 20px;
  padding: 10px;
  background-color: #fef6d9;
  border: 1px solid #ffeeba;
  color: #856404;
  border-radius: 4px;
  font-weight: bold;
}
</style>
