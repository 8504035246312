<template>
  <div class="jca-module">
    <div class="text-primary text-h4 mb-4 text-center">My Assets</div>
    <div class="select-container mb-4 justify-center">
      <AssetSelector
        :options="options"
        :selectedAsset="selectedAsset"
        @update:selectedAsset="handleModelValueUpdate"
        @add-new-asset="showAddAssetDialog"
      />
      <q-btn
        color="primary"
        class="add-new-btn neumorphic-btn"
        @click="showAddAssetDialog"
      >
        Add New Asset
      </q-btn>
    </div>

    <AddAssetDialog
      :dialogVisible="addAssetDialog"
      @close="addAssetDialog = false"
      @asset-added="handleAssetAdded"
    />
    <AssetOverview
      v-if="selectedOption"
      :selectedOption="selectedOption"
      :assetId="assetId"
      :assetName="assetName"
    />
  </div>
</template>

<script>
import axios from 'axios'
import AddAssetDialog from './AddAssetDialog.vue'
import AssetOverview from './AssetOverview.vue'
import AssetSelector from './AssetSelector.vue'

export default {
  name: 'AssetMasterView',
  components: {
    AssetOverview,
    AssetSelector,
    AddAssetDialog,
  },
  data() {
    return {
      assetId: '',
      assetName: '',
      selectedAsset: null,
      selectedOption: null,
      options: [],
      addAssetDialog: false,
    }
  },
  methods: {
    showAddAssetDialog() {
      this.addAssetDialog = true // Show the dialog
    },
    async handleAssetAdded(newAsset) {
      // Update options and selected asset
      this.options.push({ value: newAsset, label: newAsset.assetName })
      this.selectedAsset = { value: newAsset, label: newAsset.assetName }
      this.handleModelValueUpdate(this.selectedAsset)
    },
    async handleModelValueUpdate(selectedAsset) {
      if (!selectedAsset || !selectedAsset.value || !selectedAsset.value.id) {
        this.selectedOption = null
        return
      }

      this.selectedOption = {
        id: selectedAsset.value.id,
        assetName: selectedAsset.value.assetName,
      }
      this.assetId = selectedAsset.value.id
      this.assetName = selectedAsset.value.assetName

      try {
        const response = await axios.get(
          `jca/getBasicDatasForAsset/${selectedAsset.value.id}`,
        )
        this.backendData = response.data
      } catch (error) {
        console.error('Error fetching basic data:', error)
      }
    },
  },
  async mounted() {
    try {
      const response = await axios.get('jca/getAssets')
      this.options = response.data.map((asset) => ({
        value: asset,
        label: asset.assetName,
      }))
    } catch (error) {
      console.error('Error fetching assets:', error)
    }
  },
}
</script>

<style lang="scss">
@import '../jcaStyle.scss';
</style>
