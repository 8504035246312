<template>
  <div
    class="module-content neumorphic-container"
    style="background-color: #fff; border: 1px solid rgba(0, 0, 0, 0.12)"
  >
    <div class="text-primary text-h4 mb-4 text-center">Asset Overview</div>
    <q-tabs v-model="activeSection" dense>
      <q-tab name="basic-data" label="Basic Data" />
      <q-tab name="pico-per-country" label="PICO per Country" />
      <q-tab name="pico-consolidation" label="PICO Consolidation" />
      <q-tab name="evidence-library" label="Evidence Library" />
      <q-tab name="summary" label="Summary" />
    </q-tabs>

    <q-tab-panels
      v-model="activeSection"
      style="background-color: #fff; border: 1px solid rgba(0, 0, 0, 0.12)"
    >
      <q-tab-panel name="basic-data">
        <AssetBasicDataForm
          v-if="selectedOption"
          :initialData="selectedOption"
          @confirm="handleConfirm"
        />
      </q-tab-panel>
      <q-tab-panel name="pico-per-country">
        <PICOCountrySelector
          :euCountries="euCountries"
          :assetId="assetId"
          :assetName="assetName"
          @country-selected="handleCountrySelection"
          @navigate="handleTabNavigation"
          @navigate-to-basic-data="handleTabNavigation('pico-consolidation')"
        />
      </q-tab-panel>
      <q-tab-panel name="pico-consolidation">
        <PICOConsolidationMaster
          v-if="selectedOption"
          :assetId="assetId"
          :assetName="assetName"
        />
      </q-tab-panel>
      <q-tab-panel name="evidence-library">
        <PICOEvidenceLibrary v-if="selectedOption" :assetId="assetId" />
      </q-tab-panel>
      <q-tab-panel name="summary">
        <div>Overall Summary: PICOs & Evidence for the Asset</div>
        <!-- Include summary details or component here -->
      </q-tab-panel>
    </q-tab-panels>
  </div>
</template>

<script>
import axios from 'axios'
import PICOCountrySelector from '../PICO/CountrySelector/PICOCountrySelectorMasterView.vue'
import PICOConsolidationMaster from '../PICOConsolidationMaster.vue'
import PICOEvidenceLibrary from '../PICOEvidenceLibrary.vue'
import AssetBasicDataForm from './BasicDataForm.vue'

export default {
  name: 'AssetOverview',
  components: {
    AssetBasicDataForm,
    PICOCountrySelector,
    PICOConsolidationMaster,
    PICOEvidenceLibrary,
  },
  props: {
    selectedOption: Object,
    assetId: Number,
    jcaId: Number,
    assetName: String,
  },
  data() {
    return {
      localJcaId: this.jcaId || 0,
      euCountries: [],
      activeSection: 'basic-data', // Default to Basic Data tab
      picoFormData: {
        selectedCountry: '',
        population: {},
        intervention: null,
        comparisonGroup: null,
        outcome: [],
        currentPopulationId: null,
        currentInterventionId: null,
        currentComparisonGroupId: [],
        currentOutcomeIds: [],
      },
    }
  },
  async mounted() {
    try {
      const response = await axios.get('jca/euCountries')
      this.euCountries = response.data
    } catch (error) {
      console.error('Error fetching EU countries:', error)
    }
  },
  methods: {
    async handleConfirm(data) {
      const response = await axios.post('jca/saveBasicDatas', data)
      this.localJcaId = response.data // Update local state

      // Switch to the "PICO per Country" tab
      this.activeSection = 'pico-per-country'
    },
    handleTabNavigation(tabName) {
      this.activeSection = tabName // Switch to the specified tab
    },
    updatePicoFormData(updatedData) {
      // Update the local state with the new data from the child component
      this.picoFormData = { ...this.picoFormData, ...updatedData }
    },
    handlePicoSaved(newPicoId) {
      this.$emit('pico-saved', newPicoId)
    },
  },
}
</script>

<style lang="scss">
@import '../jcaStyle.scss';
</style>
