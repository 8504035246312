<template>
  <div class="pico-consolidation">
    <q-expansion-item
      label="PICO Overview"
      expand-icon="keyboard_arrow_down"
      header-style="font-weight: bold; 
                    font-size: 1.2em;
                    background-color: #FF7500;
                    color: white;"
    >
      <PICOOverview :assetId="assetId" :assetName="assetName" />
    </q-expansion-item>

    <q-expansion-item
      label="PICO Consolidation"
      expand-icon="keyboard_arrow_down"
      header-style="font-weight: bold; 
                    font-size: 1.2em;
                    background-color: #FF7500;
                    margin-top: 1em;
                    color: white;"
    >
      <PICOConsolidation :assetId="assetId" :assetName="assetName" />
    </q-expansion-item>
  </div>
</template>

<script>
import PICOOverview from './PICO/PICOOverview.vue'
import PICOConsolidation from './PICOConsolidation.vue'

export default {
  name: 'PICOConsolidationMaster',
  components: {
    PICOOverview,
    PICOConsolidation,
  },
  props: {
    assetId: {
      type: String,
      required: true,
    },
    assetName: {
      type: String,
      required: true,
    },
  },
}
</script>

<style>
.pico-consolidation {
  margin: 16px; /* Optional margin for better spacing */
}
</style>
