<template>
  <div class="select-container mb-4 justify-center">
    <q-select
      v-model="localSelectedAsset"
      :options="options"
      style="max-width: 300px; width: 100%"
      neumorphic
      dense
      label="Select Asset"
      @update:model-value="handleModelValueUpdate"
      class="q-mr-md custom-dropdown"
      placeholder="Select an Asset"
    />
  </div>
</template>

<script>
export default {
  name: 'AssetSelector',
  props: {
    options: Array,
    selectedAsset: Object,
    showAddAssetDialog: Function,
  },
  data() {
    return {
      localSelectedAsset: this.selectedAsset,
    }
  },
  watch: {
    selectedAsset(newVal) {
      this.localSelectedAsset = newVal
    },
  },
  methods: {
    handleModelValueUpdate(value) {
      this.localSelectedAsset = value
      this.$emit('update:selectedAsset', value)
    },
  },
}
</script>

<style scoped>
/* Add styles specific to the AssetSelector */
</style>
