<template>
  <div>
    <q-table
      :rows="comparisionGroupOptions"
      :columns="comparisonColumns"
      row-key="id"
      class="q-mt-md"
    >
      <template v-slot:body-cell-selected="props">
        <q-td>
          <q-checkbox :val="props.row" v-model="selectedRows" />
        </q-td>
      </template>
      <template v-slot:body-cell-label="props">
        <q-td>{{ props.row.label }}</q-td>
      </template>
    </q-table>

    <!-- Confirm and Close Buttons -->
    <div class="button-container">
      <q-btn @click="confirmSelection" label="Confirm" color="primary" />
      <q-btn
        @click="$emit('close')"
        label="Close"
        color="primary"
        class="close-button"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComparatorTable',
  props: {
    comparisionGroupOptions: {
      type: Array,
      required: true,
    },
    comparisonColumns: {
      type: Array,
      required: true,
    },
    selectedComparator: {
      type: Array,
      required: true, // Changed to Array for multiple selection
    },
    updateSelectedComparator: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      selectedRows: [], // Track the selected rows
    }
  },

  methods: {
    confirmSelection() {
      // Emit the selected rows when the Confirm button is clicked
      this.$emit('select-comparator', this.selectedRows)
      // You can also handle any additional logic here if needed
    },
    closeSelection() {
      // Emit a close event or handle the close logic
      this.$emit('close-comparator')
    },
  },
}
</script>

<style scoped>
/* Add styles specific to the comparator table */
.button-container {
  display: flex; /* Use flexbox for alignment */
  justify-content: flex-end; /* Align buttons to the right */
  margin-top: 16px; /* Add spacing as needed */
}

.close-button {
  margin-left: 8px; /* Add some space between the buttons */
}
</style>
