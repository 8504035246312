<!-- AddEvidence.vue -->
<template>
  <div>
    <div v-if="selectedPICO">
      <h5>Add Evidence for PICO</h5>
      <div>
        <p>Population: {{ selectedPICO.population }}</p>
        <p>Intervention: {{ selectedPICO.intervention }}</p>
        <p>Comparator: {{ selectedPICO.comparator }}</p>
        <!--<p>Outcome(s): {{ truncateText(selectedPICO.outcomes.join(', ')) }}</p>-->
      </div>
    </div>

    <div>
      <label>Type of evidence</label>
      <q-option-group
        v-model="evidenceType"
        :options="evidenceTypeOptions"
        inline
        @update:model-value="resetForm"
      />
      <q-input
        v-if="evidenceType === 'Other'"
        v-model="otherEvidenceType"
        label="Other evidence type"
      />
      <q-input
        v-if="evidenceType !== null"
        v-model="furtherExplanation"
        label="Further explanation"
      />
    </div>

    <div v-if="evidenceType !== null">
      <label>Result(s)</label>
      <q-select
        v-model="outcomeCategory"
        :options="outcomeCategoryOptions"
        label="Outcome category"
        @update:model-value="resetOutcomeForm"
      />

      <div v-if="outcomeCategory !== null">
        <q-input
          v-model="outcomeDefinition"
          label="Definition of outcome measure"
        />
        <q-input v-model="timeOfAssessment" label="Time of assessment" />
        <label
          >Statistical measure for the size of the treatment effect at
          population level</label
        >
        <q-option-group
          v-model="statisticalMeasure"
          :options="statisticalMeasureOptions"
          inline
        />
        <q-input
          v-if="statisticalMeasure === 'Other'"
          v-model="otherStatisticalMeasure"
          label="Other statistical measure"
        />
        <q-input
          v-if="statisticalMeasure !== null"
          v-model="statisticalMeasureExplanation"
          label="Further explanation"
        />
      </div>

      <div>
        <label>Is the data final or preliminary?</label>
        <q-option-group
          v-model="isFinalData"
          :options="finalDataOptions"
          inline
        />
      </div>

      <div v-if="isFinalData !== null">
        <h6>Results per outcome</h6>
        <q-input
          v-model="relativeEffectSize"
          label="Value for the relative effect size (enter a point estimate)"
        />
        <q-input
          v-model="pValue"
          label="Significance of the point estimate (p-value)"
        />
        <q-input
          v-model="confidenceInterval"
          label="95% confidence interval of the point estimate"
        />
        <q-input
          v-model="interventionAbsoluteEffect"
          label="Absolute value for the treatment effect of our measure (intervention)"
        />
        <q-input
          v-model="comparisonAbsoluteEffect"
          label="Absolute value for the treatment effect of the comparison group (control group)"
        />
      </div>

      <div>
        <label>Upload the documents relevant to this PICO</label>
        <q-select
          v-model="documentType"
          :options="documentTypeOptions"
          label="Type of document"
        />
        <q-input
          v-if="documentType === 'Other'"
          v-model="otherDocumentType"
          label="Other document type"
        />
        <q-file
          v-model="uploadedFile"
          label="Upload document"
          hint="Please enter in the file name in the following order: Name of asset, type of study, type of document and date of document creation"
        />
      </div>

      <div>
        <q-toggle
          v-model="anotherOutcome"
          label="Would you like to specify a different outcome for the same PICO?"
        />
      </div>
    </div>

    <div v-if="!anotherOutcome">
      <h5>Review for PICO</h5>
      <div>
        <p>Population: {{ selectedPICO.population }}</p>
        <p>Intervention: {{ selectedPICO.intervention }}</p>
        <p>Comparator: {{ selectedPICO.comparator }}</p>
        <!--<p>Outcome(s): {{ truncateText(selectedPICO.outcomes.join(', ')) }}</p>-->
      </div>

      <div>
        <h6>Evidence requirements checklist</h6>
        <q-option-group
          v-model="mortalityEvidence"
          :options="evidenceOptions"
          inline
          label="Mortality"
        />
        <q-option-group
          v-model="morbidityEvidence"
          :options="evidenceOptions"
          inline
          label="Morbidity"
        />
        <q-option-group
          v-model="hrQoLEvidence"
          :options="evidenceOptions"
          inline
          label="HrQoL"
        />
        <q-option-group
          v-model="safetyEvidence"
          :options="evidenceOptions"
          inline
          label="Safety"
        />
        <q-option-group
          v-model="otherEvidence"
          :options="evidenceOptions"
          inline
          label="Other"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddEvidence',
  props: {
    selectedPICO: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      evidenceType: null,
      otherEvidenceType: '',
      furtherExplanation: '',
      outcomeCategory: null,
      outcomeDefinition: '',
      timeOfAssessment: '',
      statisticalMeasure: null,
      otherStatisticalMeasure: '',
      statisticalMeasureExplanation: '',
      isFinalData: null,
      relativeEffectSize: '',
      pValue: '',
      confidenceInterval: '',
      interventionAbsoluteEffect: '',
      comparisonAbsoluteEffect: '',
      documentType: null,
      otherDocumentType: '',
      uploadedFile: null,
      anotherOutcome: false,
      mortalityEvidence: null,
      morbidityEvidence: null,
      hrQoLEvidence: null,
      safetyEvidence: null,
      otherEvidence: null,
      evidenceTypeOptions: [
        {
          label: 'Randomized controlled trial (RCT)',
          value: 'Randomized controlled trial (RCT)',
        },
        {
          label: 'Subgroup analysis of RCT data',
          value: 'Subgroup analysis of RCT data',
        },
        {
          label: 'Bayesian network meta-analysis (NMA)',
          value: 'Bayesian network meta-analysis (NMA)',
        },
        {
          label: "Standard NMA (e.g. Bucher's adjusted indirect comparison)",
          value: "Standard NMA (e.g. Bucher's adjusted indirect comparison)",
        },
        {
          label:
            'Patient-level adjustment to external controls (e.g.: by using propensity scores)',
          value:
            'Patient-level adjustment to external controls (e.g.: by using propensity scores)',
        },
        {
          label: 'Adjustment methods at population level: (e.g. MAIC or STC)',
          value: 'Adjustment methods at population level: (e.g. MAIC or STC)',
        },
        {
          label: 'Naïve/unadjusted comparison',
          value: 'Naïve/unadjusted comparison',
        },
        { label: 'Other', value: 'Other' },
        {
          label: 'No evidence/ missing data',
          value: 'No evidence/ missing data',
        },
      ],
      outcomeCategoryOptions: [
        'Mortality',
        'Morbidity',
        'HrQoL',
        'Safety',
        'Other',
      ],
      statisticalMeasureOptions: ['mean difference', 'OR', 'HR', 'RR', 'Other'],
      finalDataOptions: [
        {
          label: 'Yes, final',
          value: true,
        },
        {
          label: 'No, only interim results',
          value: false,
        },
      ],
      documentTypeOptions: [
        'Protocol',
        'Report',
        'Abstract',
        'Manuscript',
        'Other',
      ],
      evidenceOptions: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ],
    }
  },
  methods: {
    truncateText(text, maxLength = 100) {
      if (text.length <= maxLength) {
        return text
      }
      return `${text.slice(0, maxLength)}...`
    },
    resetForm() {
      this.furtherExplanation = ''
      this.resetOutcomeForm()
    },
    resetOutcomeForm() {
      this.outcomeDefinition = ''
      this.timeOfAssessment = ''
      this.statisticalMeasure = null
      this.otherStatisticalMeasure = ''
      this.statisticalMeasureExplanation = ''
      this.isFinalData = null
      this.relativeEffectSize = ''
      this.pValue = ''
      this.confidenceInterval = ''
      this.interventionAbsoluteEffect = ''
      this.comparisonAbsoluteEffect = ''
      this.documentType = null
      this.otherDocumentType = ''
      this.uploadedFile = null
    },
  },
}
</script>
