<template>
  <div class="intervention-form">
    <div class="form-group">
      <label>Select one of the following options:</label>
      <div class="explanatory-text">
        <p>
          In this step, you will define the intervention to be evaluated for the
          specified population. The intervention refers to the medical drug or
          procedure being assessed. It could be used as monotherapy or in
          combination with other treatments. Additionally, any potentially
          permitted background medications should be identified to reflect
          clinical practices.
        </p>
      </div>
      <div class="form-control">
        <div class="radio-group">
          <div class="radio-item">
            <input
              type="radio"
              id="monotherapy-own-drug"
              v-model="intervention"
              value="monotherapyOwnDrug"
            />
            <label for="monotherapy-own-drug">Monotherapy with own drug</label>
          </div>
          <div class="radio-item">
            <input
              type="radio"
              id="monotherapy-background"
              v-model="intervention"
              value="monotherapyBackground"
            />
            <label for="monotherapy-background"
              >Monotherapy with approved background treatment</label
            >
            <input
              v-if="intervention === 'monotherapyBackground'"
              type="text"
              v-model="value1"
              placeholder="Enter approved background treatment(s)"
              class="input-field"
              required
            />
          </div>
          <div class="radio-item">
            <input
              type="radio"
              id="combination-no-background"
              v-model="intervention"
              value="combinationNoBackground"
            />
            <label for="combination-no-background"
              >Therapy in combination with other therapy(ies) without any
              background treatment</label
            >
            <input
              v-if="intervention === 'combinationNoBackground'"
              type="text"
              v-model="value1"
              placeholder="Enter combination therapy(ies)"
              class="input-field"
            />
          </div>
          <div class="radio-item">
            <input
              type="radio"
              id="combination-background"
              v-model="intervention"
              value="combinationBackground"
            />
            <label for="combination-background"
              >Therapy in combination with other therapy(ies) and permitted
              background treatment</label
            >
            <input
              v-if="intervention === 'combinationBackground'"
              type="text"
              v-model="value1"
              placeholder="Enter combination therapy(ies)"
              class="input-field"
            />
            <input
              v-if="intervention === 'combinationBackground'"
              type="text"
              v-model="value2"
              placeholder="Enter permitted background treatment(s)"
              class="input-field"
            />
          </div>
        </div>
      </div>
      <div v-if="!isInterventionValid" class="error-message">
        Please select an intervention and fill in the required fields.
      </div>
    </div>
    <!-- Navigation Buttons -->
    <div class="form-actions">
      <q-btn
        label="Back"
        @click="emitBack"
        color="primary"
        class="add-new-btn neumorphic-btn"
      />
      <q-btn
        color="primary"
        class="add-new-btn neumorphic-btn"
        label="Next"
        @click="emitNext"
        :disabled="!isInterventionValid"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'InterventionForm',
  props: {
    picoId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      intervention: '',
      value1: '',
      value2: '',
      isInterventionValid: false, // New flag for intervention validation
    }
  },
  watch: {
    intervention(newValue) {
      console.log('Intervention changed:', newValue)
      this.validateIntervention() // Validate on intervention change
    },
    value1(newValue) {
      console.log('Value1 changed:', newValue)
      this.validateIntervention() // Validate on value1 change
    },
    value2(newValue) {
      console.log('Value2 changed:', newValue)
      this.validateIntervention() // Validate on value2 change
    },
  },
  async mounted() {
    // Fetch existing intervention data
    const interventionDataFromBackendForPicoIdId = await axios.get(
      `jca/getInterventionDataForPicoId/${this.picoId}`,
    )

    this.intervention = interventionDataFromBackendForPicoIdId.data.intervention
    this.value1 = interventionDataFromBackendForPicoIdId.data.value1
    this.value2 = interventionDataFromBackendForPicoIdId.data.value2
  },
  methods: {
    validateIntervention() {
      // Check if required fields are filled based on the selected intervention
      if (this.intervention === 'monotherapyBackground' && !this.value1) {
        this.isInterventionValid = false
      } else if (
        this.intervention === 'combinationNoBackground' &&
        !this.value1
      ) {
        this.isInterventionValid = false
      } else if (
        this.intervention === 'combinationBackground' &&
        (!this.value1 || !this.value2)
      ) {
        this.isInterventionValid = false
      } else if (this.intervention) {
        this.isInterventionValid = true // Valid if intervention is selected and all required fields are filled
      } else {
        this.isInterventionValid = false // Invalid if no intervention is selected
      }
    },
    async validateAndEmit() {
      const interventionData = {
        picoId: this.picoId,
        intervention: this.intervention,
        value1: this.value1,
        value2: this.value2,
      }

      if (this.isInterventionValid) {
        await axios.post('jca/saveIntervention', interventionData)
        this.$emit('navigate', 'comparison')
      } else {
        console.log('Please fill in all required fields.')
      }
    },
    isValid() {
      if (this.intervention === 'monotherapyBackground' && !this.value1) {
        return false
      }
      if (this.intervention === 'combinationNoBackground' && !this.value1) {
        return false
      }
      if (this.intervention === 'combinationBackground') {
        if (!this.value1 || !this.value2) {
          return false
        }
      }
      return true
    },
    emitBack() {
      this.$emit('navigate', 'population')
    },
    emitNext() {
      this.validateAndEmit()
    },
  },
}
</script>

<style>
.error-message {
  color: red;
  margin-top: 10px;
}
</style>
