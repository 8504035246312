<template>
  <div class="neumorphic-dropdown-country-selector">
    <CountrySelectorComponent
      :euCountries="euCountries"
      :assetId="assetId"
      :show-buttons="showButtons"
      @clicked="handleShowButtons"
      @select-pico-id="handleSelectedPicoId"
      @show-confirmation-modal="showConfirmationModalFunction"
    />

    <PICOForm
      v-if="picoId && picoId != -1"
      :picoId="picoId"
      :assetId="assetId"
      @navigate-to-basic-data="handleTabNavigation('pico-consolidation')"
      @new-pico-for-another-country="handleSpecifyAnotherPICOForAntoherCountry"
      @start-new-pico="handleSpecifyAnotherPICO"
    />
    <ConfirmationModal
      :isVisible="showConfirmationModal"
      title="New Country selected"
      message="Would you cancel your current PICO and start a new one?"
      :confirmText="'Yes, specify another PICO for this country'"
      :alternativeText="'Yes, specify another PICO for another country'"
      :cancelText="'No, I am done specifying PICOs'"
      @confirm="handleSpecifyAnotherPICO"
      @alternative="handleSpecifyAnotherPICOForAntoherCountry"
      @cancel="handleNoSpecifyAnotherPICO"
    />
  </div>
</template>

<script>
import ConfirmationModal from '../ConfirmationModal.vue'
import PICOForm from '../PICOForm.vue'
import CountrySelectorComponent from './CountrySelector.vue'

export default {
  name: 'CountrySelector',
  components: { PICOForm, CountrySelectorComponent, ConfirmationModal },
  props: {
    euCountries: {
      type: Array,
      required: true,
    },
    assetId: {
      type: Number,
      required: true,
    },
    assetName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      localSelectedCountry: this.selectedCountry,
      showButtons: false,
      picoId: -1,
      showConfirmationModal: false,
    }
  },
  watch: {
    assetId() {
      this.showConfirmationModalFunction()
    },
  },
  methods: {
    handleSelectedPicoId(picoId) {
      this.picoId = picoId
    },
    handleSpecifyAnotherPICOForAntoherCountry() {
      this.picoId = null
      this.localSelectedCountry = null
      this.showConfirmationModal = false
      this.showButtons = false
    },
    handleSpecifyAnotherPICO() {
      this.picoId = -1
      this.showConfirmationModal = false
      this.showButtons = false
    },
    handleShowButtons() {
      this.showButtons = true
    },
    handleNoSpecifyAnotherPICO() {
      this.showConfirmationModal = false
      this.handleTabNavigation('pico-consolidation')
    },
    showConfirmationModalFunction() {
      this.showConfirmationModal = true
    },

    handleTabNavigation(tabName) {
      this.showButtons = false
      if (tabName === 'pico-consolidation') {
        this.$emit('navigate-to-basic-data')
      }
      this.activeTab = tabName // Switch to the specified tab
    },
  },
}
</script>
